(function (angular, moment, _) {
    angular.module('MyHippoProducer.Controllers').controller('LeadListController', LeadListController);
    function LeadListController ($log, $scope, $state, $stateParams, $window, APIService, $q, UserService, spinnerService, toaster, HeapService, FlagshipService, ReactRedirectService) {
        $log.info('LeadListController');

        $scope.currentPage = 1;
        $scope.itemsPerPage = 100;
        $scope.totalItems = 0;
        $scope.isLoading = false;
        $scope.hasFetchedResults = false;

        $scope.defaultFilter = {
            scope: UserService.isCustomerService() ? 'organization' : 'producer',
            organization: 'all',
            timeRange: 'last_30_days',
            keyword: undefined,
            sortField: 'updatedAt',
            sortOrder: -1,
            sortReverse: true,
        };

        $scope.currentLead = undefined;

        const setFilters = () => {
            const stateParamsAreEmpty = Object.values($stateParams).every((value) => _.isEmpty(value));
            const cleanedStateParams = _.omit($stateParams, ['#']);
            return stateParamsAreEmpty ? $scope.filters = Object.assign({}, cleanedStateParams, $scope.defaultFilter) : Object.assign({}, $scope.defaultFilter, cleanedStateParams);
        };

        $scope.filters = setFilters();

        $scope.localeSegmentationComparator = function(v1, v2) {
            // If we don't get strings, just compare by index
            if (v1.type !== 'string' || v2.type !== 'string') {
                return (v1.index < v2.index) ? -1 : 1;
            }

            // In the dictionary, A comes before A+; therefore we override the Comparator while adding a special condition here;
            // Compare segmentation, while preferring A+ over the other scores
            if(v1.value === 'A+' && v2.value !== 'A+'){
                return 1;
            }
            if(v2.value === 'A+' && v1.value !== 'A+'){
                return -1;
            }

            // Compare strings alphabetically, taking locale into account
            return v2.value.localeCompare(v1.value);
        };

        $scope.sortPolicies = function (sortField) {
            if ($scope.filters.sortField !== sortField) {
                $scope.filters.sortField = sortField;
            }
            $scope.filters.sortOrder *= -1;
            $scope.filters.sortReverse = !$scope.filters.sortReverse;

            // Override the Comparator if the selected sorting field is 'segmentation'
            $scope.filters.sortComparator = $scope.filters.sortField === 'segmentation' ? $scope.localeSegmentationComparator : $scope.filters.sortOrder;
        };

        $scope.$watch('leads', function (newLeads) {
            if (newLeads) {
                $scope.hasCommunities = newLeads.some(l => !!l.community_name);
            }
        });

        $scope.openLeadDetails = function (leadId, state, product) {
            APIService.checkOrganizationRestriction(UserService.getOrgId(), state, product).then(({ isRestricted }) => {
                if (!isNaN(leadId)) {
                    APIService.getProducerQuoteId(leadId).then((producerQuoteId) => {
                        HeapService.trackEvent('open_lead', { 'lead_id': producerQuoteId });
                        var url = $state.href('portal.leadDetails', {
                            id: producerQuoteId,
                            // If pageId is undefined, the next step of the process,  getLeadUrl will determine the pageId
                            pageId: isRestricted ? 'organization-restriction' : undefined
                        });
                        window.open(url, '_blank');
                    });
                } else {
                    HeapService.trackEvent('open_lead', { 'lead_id': leadId });
                    var url = $state.href('portal.leadDetails', {
                        id: leadId,
                        // If pageId is undefined, the next step of the process,  getLeadUrl will determine the pageId
                        pageId: isRestricted ? 'organization-restriction' : undefined,
                    });
                    window.open(url, '_blank');
                }
            });
        };


        $scope.openRCE = function(policyId) {
            spinnerService.show('globalSpinner');
            APIService.getRCEDocument(policyId).then((response) => {
                spinnerService.hide('globalSpinner');
                $window.open(response);
            }).catch((error) => {
                if (typeof error === 'string') {
                    toaster.pop('error', 'RCE Document', error);
                }
                spinnerService.hide('globalSpinner');
            });
        };

        let downloadReportInProgress = false;
        $scope.downloadLeadsReport = function () {
            spinnerService.show('globalSpinner');
            if (downloadReportInProgress) return;
            downloadReportInProgress = true;
            APIService.downloadLeadReport($scope.filters).then(function (csv) {
                saveAs(new Blob([csv], { type: 'text/csv;charset=utf-8' }), 'quotereport_' + moment().format('YYYY-MM-DD_HH-mm-ss') + '.csv');
                toaster.pop('success', 'Quote Report', 'Successfully downloaded.');
            }).catch(function () {
                toaster.pop('error', 'Quote Report', 'An error occurred while generating the report. Please contact support.');
            }).finally(function () {
                downloadReportInProgress = false;
                spinnerService.hide('globalSpinner');
            });
        };

        $scope.hoverInRatingStarts = (lead) => {
            $scope.currentLead = {
                id: lead.id,
                revisitRatingTooltip: lead.revisitRatingTooltip,
            };
        };
        $scope.shouldUseSegmentation = false;
        $scope.$on('segmentation.turnOn', function () {
            $scope.shouldUseSegmentation = true;
        });
    }

    angular.module('MyHippoProducer.Controllers').controller('LeadDetailsController', LeadDetailsController);
    LeadDetailsController.$inject = ['$scope', '$stateParams', 'APIService', 'ReactRedirectService'];
    function LeadDetailsController ($scope, $stateParams, APIService) {
        APIService.setIntent($stateParams.id);
        $scope.clientAppUrl = '';
    }
})(window.angular, window.moment, window._);
